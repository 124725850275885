var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { FILTERS_EQUAL } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("simple-form", { scopedSlots: _vm._u([{ key: "default", fn: function(ref) {
    var handleSubmit = ref.handleSubmit;
    return [_c("a-modal", { attrs: { "after-close": _vm.afterModalClose, "destroy-on-close": "", "title": "Create Item Classification" }, scopedSlots: _vm._u([{ key: "footer", fn: function() {
      return [_c("a-button", { on: { "click": _vm.cancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "type": "primary" }, on: { "click": function($event) {
        return handleSubmit(_vm.onSave);
      } } }, [_vm._v(" Save ")])];
    }, proxy: true }], null, true), model: { value: _vm.visible, callback: function($$v) {
      _vm.visible = $$v;
    }, expression: "visible" } }, [_c("div", { staticClass: "create-item-classification-modal__content" }, [_c("a-row", { staticClass: "pl-8 pr-8 pb-5" }, [_c("select-input", { key: "countryID", attrs: { "data-source": _vm.arrCountry, "source": "id", "source-label": "name", "rules": "required", "label": "Country", "default-active-first-on-change": "", "span": 24, "placeholder": "Select Country", "on-select-change": _vm.onCountryChange } })], 1), _c("a-row", { staticClass: "pl-8 pr-8 pb-5" }, [_c("select-input", { key: "itemCode", attrs: { "advanced-filters": _vm.itemCodeFilter, "source": "itemCode", "source-label": "itemCode", "rules": "required", "label": "Item No.", "lazy": "", "search-by": "itemCode", "all-in-value": "", "fetch-options-on-filter": true, "reference": "shipment-closure.item-data", "span": 24, "placeholder": "Select Item No.", "on-select-change": _vm.onItemCodeChange } })], 1), _c("a-row", { staticClass: "pl-8 pr-8" }, [_c("text-input", { key: "itemName", attrs: { "form-item": "", "label": "Item", "max-length": 123, "span": 24, "disabled": true, "placeholder": "Item", "value": _vm.itemName } })], 1), _c("a-row", { staticClass: "pl-8 pr-8 pb-5" }, [_c("select-input", { key: "categoryID", attrs: { "data-source": _vm.listCategory, "source": "id", "source-label": "categoryName", "rules": "required", "label": "Category", "show-search": "", "all-in-value": "", "span": 24, "placeholder": "Select Category", "on-select-change": _vm.onCategoryChange } })], 1), _c("a-row", { staticClass: "pl-8 pr-8" }, [_c("text-input", { key: "portfolioName", attrs: { "form-item": "", "label": "Portfolio", "max-length": 123, "span": 24, "disabled": true, "placeholder": "Portfolio", "value": _vm.portfolioName } })], 1)], 1)])];
  } }]) });
};
var staticRenderFns$1 = [];
render$1._withStripped = true;
var CreateItemClassificationData_vue_vue_type_style_index_0_lang = "";
const __vue2_script$1 = {
  name: "EditItemClassificationData",
  inject: ["crud", "apiUrl", "resourceName"],
  data() {
    return {
      itemName: "",
      portfolioName: "",
      selectedCountryID: 1,
      arrCountry: [],
      listCategory: [],
      objItem: [],
      objCategory: [],
      isFetching: false,
      visible: true
    };
  },
  computed: {
    itemCodeFilter() {
      if (!this.selectedCountryID)
        return null;
      return [
        [
          "country",
          { operator: FILTERS_EQUAL, value: this.selectedCountryID }
        ]
      ];
    }
  },
  async created() {
    let datacountry = await this.axios.get(`${this.apiUrl}/shipment-closure/country-data`);
    this.arrCountry = datacountry.data.results;
    const { data } = await this.axios.get(`${this.apiUrl}/shipment-closure/category-data?fe=((IsInactive Eq 'false'))`);
    this.listCategory = data.results;
  },
  methods: {
    async onCountryChange(value) {
      this.selectedCountryID = value;
      this.storeValue("countryID", this.selectedCountryID);
      this.isFetching = false;
    },
    async onCategoryChange(value) {
      this.portfolioName = value.portfolioName;
      this.storeValue("categoryID", value.id);
    },
    async onItemCodeChange(value) {
      this.itemName = value.itemCodeDesc;
      this.storeValue("itemCode", value.itemCode);
    },
    afterModalClose() {
      this.$router.push(`/shipment-closure/item-classification-data/`);
    },
    cancel() {
      this.visible = false;
      this.$router.push(`/shipment-closure/item-classification-data/`);
    },
    async onSave() {
      await this.crud.submitEntity("create");
      this.crud.clearEntity("createForm");
      this.visible = false;
      this.crud.fetchList();
    },
    storeValue(resourceKey, value) {
      this.createForm = __spreadProps(__spreadValues({}, this.createForm), {
        [resourceKey]: value
      });
      this.crud.updateEntity("createForm", {
        resourceKey,
        value
      });
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
__component__$1.options.__file = "src/views/ItemClassificationData/CreateItemClassificationData/CreateItemClassificationData.vue";
var CreateItemClassificationData = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", { staticClass: "create-item-classification-data" }, [_c("resource", { attrs: { "name": "shipment-closure.item-data", "api-url": _vm.apiUrl, "resource-id-name": "itemCode" } }), _c("resource", { attrs: { "name": "shipment-closure.item-classification-data", "api-url": _vm.apiUrl, "redirect-route": "/shipment-closure/item-classification-data" } }, [_c("create-item-classification-data")], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateItemClassificationData
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
__component__.options.__file = "src/views/ItemClassificationData/CreateItemClassificationData/index.vue";
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
